import { Controller } from '@hotwired/stimulus';
import axios, { AxiosResponse } from 'axios';
import { getTestimonials } from '../api/api';

export default class extends Controller {
  static values = {
    page: { type: Number, default: 1 },
    previousPage: { type: Number, default: 1},
    currentReadMoreExpanded: { type: String, default: ''},
    previousReadSection: { type: String, default: ''},
  };
  static targets = [
    'paginationFragment',
    'paginateNext',
    'paginateBack',
    'paginateDropdown',
    'dropdownPageNumber',
    'shareBtn',
    'shareBtnMobile',
    'agentPhone',
  ];

  static classes = ['paginationLoading', 'dropdownOpen'];

  initialize() {
    this.personGuid = document.currentScript?.getAttribute('data-personguid');
    this.activeReadMoreCard = null;
    if (!this.personGuid) {
      console.error('Missing personguid in script tag');
    }
    this.addDropdownListener();
  }

  connect() {
    if (this.personGuid) {
      this.fetchInitialView();
    } else {
      this.setUnconfiguredState();
    }
  }

  addDropdownListener() {
    document.addEventListener('click', (e) => {
      const shareDialogEl = document.getElementById('__mc_widget-share-dialog');
      const shareDialogElMobile = document.getElementById('__mc_widget-share-dialog-mobile');
      const dropdownContent = document.getElementById('dropdown-content');

      if (shareDialogEl) {
        shareDialogEl.contains(e.target)
          ? true
          : !shareDialogEl.classList.contains(
              '__mc_widget-share-dialog-visible'
            ) && e.target.matches('#__mc_widget-share-btn')
          ? shareDialogEl.classList.add('__mc_widget-share-dialog-visible')
          : shareDialogEl.classList.remove('__mc_widget-share-dialog-visible');
      }

      if (shareDialogElMobile) {
        shareDialogElMobile.contains(e.target)
          ? true
          : !shareDialogElMobile.classList.contains(
            '__mc_widget-share-dialog-visible'
          ) && e.target.matches('#__mc_widget-share-btn-mobile')
            ? shareDialogElMobile.classList.add('__mc_widget-share-dialog-visible')
            : shareDialogElMobile.classList.remove('__mc_widget-share-dialog-visible');
      }

      if (this.hasPaginateDropdownTarget && this.paginateDropdownTarget) {
        this.paginateDropdownTarget.contains(e.target)
          ? true
          : !this.paginateDropdownTarget.classList.contains('dropdown-open') && e.target.matches('.__mc_widget-dropdown')
          ? this.paginateDropdownTarget.classList.add('dropdown-open')
          : this.paginateDropdownTarget.classList.remove('dropdown-open');
      }
    });
  }

  fetchInitialView() {
    getTestimonials(this.personGuid).then((res) => {
      if (res.status === 204) {
        this.setUnconfiguredState('Widget not configured');
        return;
      }
      const data = JSON.parse(res.data);
      this.element.innerHTML = data.html;
      const selectedBtn = document.getElementById('__mc_widget-paginate-page-item-btn-1') ? document.getElementById('__mc_widget-paginate-page-item-btn-1') : null;
      if (selectedBtn) {
        selectedBtn.classList.add('__mc_widget-paginate-page-item-btn-selected')
      }
      if (this.hasPaginateBackTarget) {
        this.paginateBackTarget.setAttribute('disabled', '');
      }
    });
  }

  async paginate() {
    this.setLoadingClass('add');
    getTestimonials(this.personGuid, this.pageValue)
      .then((res) => {
        const data = JSON.parse(res.data);
        if (this.pageValue > 1) {
          this.currentReadMoreExpandedValue = '';
          this.updatePaginationFragment(
            data.html
          );
        } else {
          this.replaceTemplate(data.html);
        }
        this.updateUiState(data.testimonials);
      })
      .catch((err) => console.error(err));
  }

  previousPage() {
    if (this.pageValue > 1) {
      this.pageValue--;
      this.paginate();
    }
  }

  nextPage() {
    this.pageValue++;
    this.paginate();
  }

  setPageNumber() {
    if (this.hasDropdownPageNumberTarget) {
      this.dropdownPageNumberTarget.innerText = this.pageValue;
    }
  }

  pageSelected(event) {
    this.previousPageValue = this.pageValue;
    this.pageValue = event.params.page;
    this.paginateDropdownTarget.classList.remove(this.dropdownOpenClass);
    this.paginate();
  }

  setLoadingClass(action) {
    this.paginationFragmentTarget.classList.add(this.paginationLoadingClass);
    this.paginationFragmentTarget.classList.remove(this.paginationLoadingClass);
  }

  disableNextBtn(lastPage) {
    if (this.pageValue === lastPage) {
      this.paginateNextTarget.setAttribute('disabled', '');
      return;
    }
    this.paginateNextTarget.removeAttribute('disabled');
  }

  disableBackBtn() {
    if (this.pageValue > 1) {
      this.paginateBackTarget.removeAttribute('disabled');
      return;
    }
    this.paginateBackTarget.setAttribute('disabled', '');
  }

  updateUiState(testimonials) {
    document
      .getElementById('__mc_widget-pagination-fragment-container')
      .scrollIntoView({ behavior: 'smooth' });
    this.setPageNumber();
    this.setLoadingClass('remove');
    if (this.hasPaginateNextTarget && this.hasPaginateBackTarget) {
      const lastPage = parseInt(this.dropdownPageNumberTarget.dataset.lastPage);
      this.disableNextBtn(lastPage);
      this.disableBackBtn();
    }
  }

  setUnconfiguredState(message = 'Widget not configured') {
    this.element.innerHTML =
      '<div class="__mc_widget-warning-block"><div class="warning-circle">!</div>' +
      message +
      '</div>';
  }

  updatePaginationFragment(html) {
    this.paginationFragmentTarget.innerHTML = html;

    const previousPage = document.getElementById('__mc_widget-paginate-page-item-btn-' + this.previousPageValue);
    const currentPage = document.getElementById('__mc_widget-paginate-page-item-btn-' + this.pageValue);
    const pageOneButton = document.getElementById('__mc_widget-paginate-page-item-btn-1');

    previousPage.classList.remove('__mc_widget-paginate-page-item-btn-selected');
    currentPage.classList.add('__mc_widget-paginate-page-item-btn-selected');
    pageOneButton.classList.remove('__mc_widget-paginate-page-item-btn-selected');
  }

  replaceTemplate(html) {
    this.element.innerHTML = html;
    const pageOneButton = document.getElementById('__mc_widget-paginate-page-item-btn-1');
    pageOneButton.classList.add('__mc_widget-paginate-page-item-btn-selected');
  }

  workWith() {
    window.open('/contact-me', '_blank');
  }

  readMoreSelected(e) {
    const readMoreClass = '__mc_widget-testimonial-read-more'
    const targetedCard = document.querySelector('#' + e.params.readmoreselection.testimonial);
    if (this.activeReadMoreCard && targetedCard !== this.activeReadMoreCard) {
      this.activeReadMoreCard.classList.remove(readMoreClass);
    }
    this.activeReadMoreCard = document.querySelector('#' + e.params.readmoreselection.testimonial);

    if (!this.activeReadMoreCard.classList.contains(readMoreClass)) {
      this.activeReadMoreCard.classList.add(readMoreClass);
    } else if(this.activeReadMoreCard.classList.contains(readMoreClass)) {
      this.activeReadMoreCard.classList.remove(readMoreClass);
    }
  }

  shareBtnClicked(e) {
    this.shareBtnTarget.classList.remove('__mc_widget-share-dialog-visible');
    this.shareBtnMobileTarget.classList.remove('__mc_widget-share-dialog-visible');

    if (e.params && e.params.shareName === 'facebook') {
      window.open('http://www.facebook.com/share.php', '_blank');
      return;
    }

    window.open('https://twitter.com/intent/tweet', '_blank');
  }
}
