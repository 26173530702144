import axios, {Axios, AxiosResponse} from "axios";

const sourceDomain = new URL(document.currentScript.src).origin;
const http = new Axios({
  baseURL: sourceDomain + '/widget-api/testimonial/v1',
});

export const getTestimonials = async (personGuid, page = null) =>  {
  const resp = await http.get('/' + personGuid + '/render', {params: {page}});
  if (resp) {
    return resp;
  }
}
