import axios from "axios";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import "./scss/_app.scss";

const initialize = () => {
  const html = document.createElement('div');
  html.setAttribute('data-controller', 'widget');
  html.setAttribute('data-widget-current-page', 1);
  html.setAttribute('data-widget-pagination-loading-class', '__mc_widget-pagination-loading');
  html.setAttribute('data-widget-dropdown-open-class', 'dropdown-open');
  // __mctw
  document.getElementById('__mc-testimonial-widget').after(html);
  // document.getElementById('__mc-testimonial-widget').parentElement.prepend(document.createElement('<div class="__mc_widget-layout" data-controller="widget" data-widget-current-page-value="1" data-widget-pagination-loading-class="__mc_widget-pagination-loading" data-widget-dropdown-open-class="dropdown-open"></div>'));
  console.log();
  window.Stimulus = Application.start();
  const context = require.context("./controllers", true, /\.js$/);
  Stimulus.load(definitionsFromContext(context));
  // Stimulus.debug = true;
};

// https://stackoverflow.com/questions/9457891/how-to-detect-if-domcontentloaded-was-fired
if (/complete|interactive|loaded/.test(document.readyState)) {
  initialize();
} else {
  document.addEventListener("DOMContentLoaded", initialize);
}
